<template>
  <div id="account">
    <PageHeader
      :pageHeader="pageHeader"
      :kvTitle1Color="kvTitle1Color"
      :kvTitle2Color="kvTitle2Color"
    />
    <router-view />
  </div>
</template>

<script>
import '@/assets/scss/account.scss';

import PageHeader from '@/components/PageHeader.vue';
import { AccountKv } from '@/lib/const';

export default {
  title: '會員專區',
  name: 'AccountHome',
  data() {
    return {
      ...AccountKv,
      kvTitle1Color: 'txt-white',
      kvTitle2Color: 'txt-white',
    };
  },
  components: {
    PageHeader,
  },
};
</script>
